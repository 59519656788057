















import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

@Component
export default class Screens extends Vue {
  @Prop() private images!: Array<any>;
}
